import { BaseStore, Store, Field } from '@zento/lib/stores/BaseStore';
import { currentStoreView } from '@vue-storefront/core/lib/multistore';

import { storeConfig, StoreConfigQuery } from './operations.graphql';

@Store
export class StoreConfig extends BaseStore {
  /**
   * Store config types
   */
  @Field
  private storeConfigData: StoreConfigQuery['storeConfig'];

  /**
   * Store config
   */
  public async storeConfig(opts?: { includeAnalytics: boolean }) {
    let config: StoreConfigQuery = { storeConfig: {} };
    const options = {
      // Add more when needed
      includeFBConversion: true,
      includeAnalytics: true,

      ...opts,
    };

    try {
      // Add current store code to header
      this.dataSources.graphQl.customHeaders = {
        ...this.dataSources.graphQl.customHeaders,
        Store: currentStoreView().storeCode || 'default',
      };

      config = await this.dataSources.graphQl.queue({
        ...storeConfig,
        params: { ...options },
      });
    } catch (e) {
      console.error('Config Store (storeConfig): ', e);
    }

    this.storeConfigData = config.storeConfig;

    return this.storeConfigData;
  }

  /**
   *  Store config getter
   */
  public get storeConfigurations() {
    return this.storeConfigData || {};
  }
}
